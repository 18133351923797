import * as React from 'react';
import '../../../styles/styles.css';
import { siteLayout } from './SiteLayout.module.css';

interface SiteLayoutProps {
  children?: React.ReactNode;
}

function SiteLayout(props: SiteLayoutProps) {
  return <div className={siteLayout}>Isometric{props.children}</div>;
}

export default SiteLayout;
